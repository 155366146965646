
/*logo*/
.logoNexaPK{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%; /* Asegura que la imagen no se desborde */
  top: 120px;
  z-index: 0;
}


.App {
  text-align: center;
}
body {
  background-color: #dfe2e4 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/******loader******/
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Fondo semi-transparente oscuro */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  /* Asegura que el overlay esté por encima del contenido */
}

.loading {
  position: fixed;
  float: left;
  top: 50%;
  left: 50%;
  height: 120px;
  padding: 0px;
  width: 160px;
  margin-top: -50px;
  margin-left: -70px;
  box-sizing: border-box;
  border-left: 2px solid #53ffce;
  border-bottom: 2px solid #53ffce;
  padding: 10px;
  box-sizing: border-box;
}

@keyframes loading {
  0% {
      background-color: #cd0a00;
  }

  30% {
      background-color: #fa8a00;
  }

  50% {
      height: 100px;
      margin-top: 0px;
  }

  80% {
      background-color: #91d700;
  }

  100% {
      background-color: #cd0a00;
  }
}

.loading-bar {
  height: 10px;
  width: 30px;
  background-color: #91d700;
  display: inline-block;
  margin-top: 90px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.loading-1 {
  animation: loading 2.5s infinite 0s;
}

.loading-2 {
  animation: loading 2.5s infinite 0.5s;
}

.loading-3 {
  animation: loading 2.5s infinite 1s;
}

.loading-4 {
  animation: loading 2.5s infinite 1.5s;
}
